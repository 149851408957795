.window {
  position: absolute;
  background-color: #c0c0c0;
  padding: 2px;
  min-height: 100px;
  min-width: 300px;
  border-top: 2px solid rgb(250, 250, 250);
  border-right: 1px solid rgb(90, 90, 90);
  border-bottom: 1px solid rgb(90, 90, 90);
}

.titlebar {
  background: linear-gradient(90deg, navy, #1084d0);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3px;
  min-width: min-content;
}

.framecontrols {
  display: flex;
}

.framecontrols button {
  padding: 0;
  display: block;
  min-width: 16px;
  min-height: 14px;
}

.framecontrols button:active {
  padding: 0;
}

.framecontrols button:focus {
  outline: none;
}

.appicon {
  width: 15px;
  height: 15px;
}
