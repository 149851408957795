*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: 'MS Sans Serif';
  src: url('./assets/fonts/MS-Sans-Serif.ttf');
}

body {
  line-height: 16px;
  font-family:
    'MS Sans Serif',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family:
    source-code-pro,
    Menlo,
    Monaco,
    Consolas,
    'Courier New',
    monospace;
}

.windowsarea {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
}

a,
li {
  text-decoration: none;
}
