.container {
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 25px;
}

.icon {
  width: 25px;
  height: 25px;
  margin-bottom: 5px;
}

.appname {
  font-size: 12px;
  font-weight: 300;
  color: #fff;
  margin: 1px;
  text-transform: capitalize;
}

.container:focus,
.icon:focus {
  filter:
    grayscale(100%) brightness(30%) sepia(100%) hue-rotate(-180deg)
    saturate(400%) contrast(0.9);
}
